/*
 *  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *  http://aws.amazon.com/apache2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */
import _ from 'lodash';
import { createForm } from '../../helpers/form';

const getFields = ({ projectIdOptions, cidr, rootVolumeSizeRange = { min: 32, max: 2048, default: 32 } }) => {
  const fields = {
    name: {
      label: 'Workspace Name',
      placeholder: 'Combination of letters, numbers and -, starts with a letter.',
      rules: 'required|string|between:3,128|WorkspaceNameRule1|WorkspaceNameRule2',
    },
    description: {
      label: 'Description',
      placeholder: 'The description of this workspace',
      rules: 'required|string|between:1,2048',
    },
    projectId: {
      label: 'Project ID',
      placeholder: 'The project ID to associate with this workspace',
      value: projectIdOptions[0].value,
      rules: ['required', 'string', 'min:1', 'max:100'],
      extra: {
        options: projectIdOptions,
        // explain: 'The project ID you wish to charge usage to.',
      },
    },
    rootVolume: {
      label: 'C Drive/Root Volume Size (GB)',
      value: `${Math.max(rootVolumeSizeRange.min, rootVolumeSizeRange.default ?? 32)}`,
      rules: ['required', 'integer', `min:${rootVolumeSizeRange.min}`, `max:${rootVolumeSizeRange.max}`],
      extra: {
        explain: 'Adjust the amount of storage space available on your workspace.',
      },
    },
    envTypeConfigId: {
      label: 'Configuration',
      placeholder: 'The configuration for the workspace',
      extra: {
        explain: 'Select your hardware configuration. Looking for something not listed? Contact us at race@rmit.edu.au.',
      },
      rules: 'required',
    },
    enableHyperthreading: {
      label: 'Hyperthreading',
      extra: {
        explain: 'If disabled, this will half your number of vCPUs, where 1 vCPU will equal 1 physical core.',
      },
      value: true,
      rules: ['boolean', 'required'],
    },
  };

  if (!_.isUndefined(cidr)) {
    fields.cidr = {
      label: 'Restricted CIDR',
      extra: {
        explain: `This is your IP address. Your workspace will only be reachable from this CIDR (IP address range) and on campus. You can change your IP address whitelist after creating the workspace.`,
      },
      placeholder: 'The CIDR range to restrict workspace access to',
      rules: 'required|cidr',
      value: cidr,
    };
  }

  return fields;
};

function getCreateInternalEnvForm(...args) {
  return createForm(getFields(...args));
}

// eslint-disable-next-line import/prefer-default-export
export { getCreateInternalEnvForm };
