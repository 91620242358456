/* eslint-disable prettier/prettier */
import React from 'react';

export function ChatbotIcon() {
    return (
        <svg width="36" height="32" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path d="M36 13.143c0 2.33-.804 4.483-2.41 6.459-1.608 1.975-3.79 3.535-6.55 4.68C24.281 25.427 21.268 26 18 26c-.938 0-1.908-.054-2.913-.16-2.652 2.343-5.732 3.964-9.241 4.86-.656.188-1.42.336-2.29.443a.788.788 0 0 1-.613-.18 1.01 1.01 0 0 1-.351-.584v-.02c-.04-.053-.044-.134-.01-.24.033-.108.046-.175.04-.202-.007-.026.023-.09.09-.19l.12-.181c.014-.02.06-.077.141-.171a71.441 71.441 0 0 1 .783-.874c.322-.355.553-.61.694-.763.14-.154.348-.419.623-.794.274-.375.492-.716.652-1.024.161-.308.342-.703.543-1.186.2-.482.375-.99.522-1.526-2.103-1.192-3.76-2.666-4.972-4.42C.606 17.033 0 15.152 0 13.143c0-1.741.475-3.405 1.426-4.992.951-1.587 2.23-2.957 3.837-4.109C6.871 2.891 8.786 1.977 11.01 1.3 13.232.624 15.562.286 18 .286c3.268 0 6.281.572 9.04 1.717 2.76 1.145 4.942 2.706 6.55 4.681C35.195 8.66 36 10.812 36 13.143z" fill="#FFF"/>
                <path d="M21.875 7.25a.6.6 0 0 1 .45.18c.116.121.175.275.175.463v1.286a.635.635 0 0 1-.176.462.6.6 0 0 1-.449.18H8.125a.6.6 0 0 1-.45-.18.635.635 0 0 1-.175-.462V7.893c0-.188.059-.342.176-.462a.6.6 0 0 1 .449-.181h13.75zM21.875 17.75a.6.6 0 0 1 .45.18c.116.121.175.275.175.463v1.286a.635.635 0 0 1-.176.462.6.6 0 0 1-.449.18H8.125a.6.6 0 0 1-.45-.18.635.635 0 0 1-.175-.462v-1.286c0-.188.059-.342.176-.462a.6.6 0 0 1 .449-.181h13.75z" fill="#B1B1B1"/>
                <path d="M28.375 12.5a.6.6 0 0 1 .45.18c.116.121.175.275.175.463v1.286a.635.635 0 0 1-.176.462.6.6 0 0 1-.449.18h-13.75a.6.6 0 0 1-.45-.18.635.635 0 0 1-.175-.462v-1.286c0-.188.059-.342.176-.462a.6.6 0 0 1 .449-.181h13.75z" fill="#A4C7CF"/>
            </g>
        </svg>
    )
}

export function ChatbotAvatar() {
    return (
        <svg fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.96 61.5">
            <g><path fill='#E61E2A' d="m32.13,0h-8.2v6.66h-13.66v13.89H0v20.4h10.27v13.89h13.66v6.64h7.23c16.89,0,31.8-13.64,31.8-30.67S49.05,0,32.13,0"/></g>
        </svg>
    )
}