/* eslint-disable prettier/prettier */
import _ from 'lodash';
import c from 'classnames';
import React, { createRef } from 'react';
import { decorate, runInAction, observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { ChatbotIcon } from './chatbot/ChatbotIcon';
import Bubble from './chatbot/Bubble';

class ChatBot extends React.Component {
    constructor() {
        super();
        
        runInAction(() => {
            this.isShowing = false;
            this.waitingAssistantResponse = false;
            this.currentSessionId = null;
            this.ignoredSessionIds = [];
            this.assistantPendingMessage = '';
            this.chatHistory = [];
            this.chatbotReady = false;
        })
    }

    conversationMainRef = createRef();

    render() {
        return (
            <div className={c('fixed-position-icon', { 'show-chat': this.isShowing })} onClick={this.isShowing ? undefined : this.toggleChat}>
                { this.isShowing ? this.renderChatPage() : <ChatbotIcon /> }
            </div>
        )
    }

    renderChatPage = () => {
        return (
            <div className='chat-page'>
                <Icon name='angle down' className='close-icon' onClick={this.toggleChat} />
                <div className='conversations' ref={this.conversationMainRef}>
                    <Bubble theRole='assistant'>Hello there, I&apos;m RACE-Buddy! I&apos;m a support bot that is part of the RACE Pit Crew, how can I help you today?</Bubble>
                    {  _.map(this.chatHistory, (chat, index) => (
                        <Bubble theRole={chat.role} key={index}>
                            { chat.content }
                        </Bubble>
                    ))  }
                    <Bubble 
                        theRole='assistant'
                        isPending={this.waitingAssistantResponse}
                        className={c('bubble', 'assistant', { hidden: !this.waitingAssistantResponse })}
                    >
                        { this.assistantPendingMessage }
                    </Bubble>
                    { 
                        !this.waitingAssistantResponse && this.chatHistory.length ?
                        <div
                            className='reset-conversation'
                            onClick={this.resetConversation}
                        >
                            Reset Conversation
                        </div> : <></>
                    }
                </div>
                <form className='chat-input' onSubmit={this.submitMessage}>
                    <input type='text' name='message' placeholder='Type your message...' />
                    <div className='icon-container'>
                        <input type='submit' className={c('submit-btn', {'disabled': this.waitingAssistantResponse})} />
                        <Icon 
                            className='icon'
                            name={ this.waitingAssistantResponse ? 'stop circle' : 'send' } 
                            onClick={ this.waitingAssistantResponse ? this.interruptResp : undefined }
                        />
                    </div>
                </form>
            </div>
        )
    }

    autoScroll = () => {
        if(this.conversationMainRef.current) {
            this.conversationMainRef.current.scrollTo({
                top: this.conversationMainRef.current.scrollHeight,
                behavior: 'smooth'
            })
        }
    }

    submitMessage = (event) => {
        event.preventDefault();
        const message = event.target.message.value;

        if(!message || this.waitingAssistantResponse || !this.wsClient || !this.chatbotReady) return;

        this.startResponse();
        this.wsClient.send(JSON.stringify({ action: "Completions", prompt: message, history: this.chatHistory }));
        this.addNewHistory('user', message)
        event.target.message.value = '';
    }
    
    interruptResp = () => {
        this.ignoredSessionIds.push(this.currentSessionId);
        this.currentSessionId = null;
        this.updateAssistantResponse(`${this.assistantPendingMessage  }[ Interruped Conversation ]`, true);
    }

    addNewHistory = (role, content) => {
        this.chatHistory.push({ role, content });
        this.autoScroll();
    }

    resetChatbotState = () => {
        this.chatbotReady = false;
        this.wsClient = null;
    }

    toggleChat = () => {
        this.isShowing = !this.isShowing;
        if(this.isShowing) {
            // eslint-disable-next-line no-unused-expressions
            this.wsClient && this.wsClient.close();
            if(!process.env.REACT_APP_CHATBOT_WS_URL) {
                console.error("ws url not set");
                this.resetChatbotState();
                return;
            }

            this.wsClient = new WebSocket(process.env.REACT_APP_CHATBOT_WS_URL);
           
            this.wsClient.onerror = () => {
                console.error("load ws error")
                this.resetChatbotState();
            }

            this.wsClient.onopen = () => {
                runInAction(() => {
                    this.chatbotReady = true;
                });
            }

            this.wsClient.onmessage = event => {
                const data = JSON.parse(event.data);
                const { content, isFinished, sessionId, completed } = data;
                if(this.ignoredSessionIds.includes(sessionId) || completed) return;
                if(!this.sessionId) {
                    runInAction(() => {
                        this.currentSessionId = sessionId;
                    })
                }
                this.updateAssistantResponse(content, isFinished);
            }

            this.wsClient.onclose = () => this.resetChatbotState;

        } else {
            // eslint-disable-next-line no-unused-expressions
            this.wsClient && this.wsClient.close();
        }
    }

    startResponse = () => {
        this.waitingAssistantResponse = true;
    }

    updateAssistantResponse = (message, isFinished) => {
        if(isFinished) {
            this.waitingAssistantResponse = false;
            this.assistantPendingMessage = '';
            this.addNewHistory('assistant', message);
        } else {
            this.assistantPendingMessage = message;
            this.autoScroll();
        }
    }

    resetConversation = () => {
        this.chatHistory = [];
    }
}

decorate(ChatBot, {
    isShowing: observable,
    waitingAssistantResponse: observable,
    assistantPendingMessage: observable,
    chatHistory: observable,
    wsClient: observable,
    chatbotReady: observable,
    currentSessionId: observable,
    ignoredSessionIds: observable,
    submitMessage: action,
    interruptResp: action,
    addNewHistory: action,
    startResponse: action,
    updateAssistantResponse: action,
    toggleChat: action,
    resetChatbotState: action,
    resetConversation: action,
});
  
export default inject()(withRouter(observer(ChatBot)));
