/* eslint-disable prettier/prettier */
import React from "react";
import c from 'classnames';
import { Icon } from 'semantic-ui-react';
import MarkDown from "../../helpers/Markdown";
import { ChatbotAvatar } from "./ChatbotIcon";

export default function Bubble({ theRole, children, className = null, isPending = false }) {

    const renderDotsAnimation = () => {
        return (
            <div className='dots'>
                <Icon name='circle' className='loading-dot' />
                <Icon name='circle' className='loading-dot' />
                <Icon name='circle' className='loading-dot' />
            </div>
        )
    }

    return (
        <div className={className || c('bubble', theRole)}>
            <div className="avatar">{ theRole === 'assistant' ? <ChatbotAvatar /> : <Icon name="user" /> }</div>
            <div className="content">{ isPending && !children ? renderDotsAnimation() : <MarkDown>{children}</MarkDown>}</div>
        </div>
    )
}